<template>
  <div id="zl" class="zl">
    <!-- <div class="rightlogo"></div> -->
    <!-- <div class="box-header" :style="scllorTp?'background:#fff':'  background: rgba(255, 255, 255, 0.5);'">
       
        <p>
            <a-button  @click="backFun"  style="margin-right:20px;" size="small"><a-icon type="home" /></a-button>
          <a href="https://www.educg.net/">
            <img src="../assets/logo (2).png" width="100px" class="minH" /><span class="minF"
              style="font-size: 16px; color: #666;    margin-left: 10px;"
              ></span
            >
          </a>
        </p>
        <p style="font-size:16px; color:rgb(53, 53, 53);font-weight: bold;line-height: 24px;" class="divider">服务热线：<i style="font-size:30px;color:#de7603">4000697117</i><a-icon type="phone" style="color:#de7603"/></p>

        
      </div> -->
    <div class="zl_box">
      <!-- <div class="mask"></div> -->

      <div class="conDD">
        <div class="container">
        </div>
      </div>
    </div>
    <!-- <a-divider style="margin:30px 0;font-size:20px;font-weight: bold; color:#636160;" >  <a-icon type="bulb" style=""/>产品垂询 <span style="font-size:14px;color:#de7603">【填写信息，专属客服为您一对一服务】</span></a-divider> -->
    <div class="con">
      <!-- <a-tabs default-active-key="1" @change="tabcallback">
        <a-tab-pane key="1">
          <span slot="tab">
            <a-icon type="bulb" />
            产 品 垂 询
          </span> -->
     
        
      <a-form-model
        v-if="subSure"
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
      <h3 class="from-title">{{form.schoolName}}   {{ form.collegeName }} 专属服务群</h3>
      <p style="text-align: center;margin-bottom:40px;">为高效、精准响应您提出的问题，请加群后实名备注，问题和需求直接在群内反馈</p>
<p style="text-align: center;"> <el-image
      style="width: 300px; height: 300px"
      :src="form.url"
      fit="fit"></el-image></p>
    
      
      </a-form-model>
    </div>

 
  </div>
</template>


<script>
export default {
  data() {
    return {
      titleD: "教学平台管理",
      scllorTp: false,
      subdisabled: false,
      fileList: [],
      fileName: "",
      subSure: true,
      subSureKK: true,
      courselist: [],
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      other: "",
      form: {
        collegeName:'',
        url:'',
        schoolName:''
      },
  
    };
  },
  mounted() {
    this.courselistFun();
    // if(this.$route.query.educg){
    //   document.title = "预约演示";
    //   this.titleD = '预约演示'
    // }
    // else {
    //   document.title = "试用及咨询";
    //   this.titleD = '试用及咨询'
    // }

    window.addEventListener("scroll", this.scrolling);
  },

  methods: {
    scrolling() {
      // 滚动条距文档顶部的距离
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // 滚动条滚动的距离
      let scrollStep = scrollTop - this.oldScrollTop;
      console.log("header 滚动距离 ", scrollTop);
      if (scrollTop > 100) {
        this.scllorTp = true;
      } else {
        this.scllorTp = false;
      }
      // 更新——滚动前，滚动条距文档顶部的距离
      // this.oldScrollTop = scrollTop;

      // //变量windowHeight是可视区的高度
      // let windowHeight =
      //   document.documentElement.clientHeight || document.body.clientHeight;
      // //变量scrollHeight是滚动条的总高度
      // let scrollHeight =
      //   document.documentElement.scrollHeight || document.body.scrollHeight;

      // //滚动条到底部的条件
      // if (scrollTop + windowHeight == scrollHeight) {
      //   //你想做的事情
      //   console.log("header  你已经到底部了");
      // }
      // if (scrollStep < 0) {
      //   console.log("header 滚动条向上滚动了！");
      // } else {
      //   console.log("header  滚动条向下滚动了！");
      // }
      // // 判断是否到了最顶部
      // if (scrollTop <= 0) {
      //   console.log("header 到了最顶部")
      // }
    },

    // qdWindow() {
    //     document.getElementById('#qdmodal').modal('show');
    //     // $('#qdmodal')
    // },
    changeFile(event) {
      console.log(event.target.files[0]);
      debugger;
      const isLt2M = event.target.files[0].size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("图片大于1MB!");
        return;
      }
      this.fileName = event.target.files[0].name;
      this.formKK.file = event.target.files[0];
    },

    beforeUpload(file) {
      return new Promise((resolve, reject) => {
        console.log(this.fileList);
        console.log(file);
        if (this.fileList.length >= 1) {
          this.$message.error("只能上传一张图片！");
          return reject(false);
        }
        const isJpgOrPng =
          file.type === "image/jpeg" || file.type === "image/png";
        // //   if (!isJpgOrPng) {
        // //     this.$message.error('You can only upload JPG file!');
        // //   }
        const isLt2M = file.size / 1024 / 1024 < 1;
        if (!isLt2M) {
          this.$message.error("图片大于1MB!");
          return reject(false);
        }
        this.formKK.file = file;
        return resolve(true);
      });
    },
    courselistFun() {
 
      let data = {
        op: "getpaas",
        code:this.$route.query.code
      }
      let _this = this;
      this.$ajax
        .post(this.paas, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.form.collegeName=response.data.data.college
            _this.form.schoolName=response.data.data.school
            _this.form.url=response.data.data.icon

          } else {
            _this.$message.success(response.data.msg);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    tabcallback(key) {
      console.log(key);
    },
    authtypeFun() {
      this.form.company = "";
      this.form.college = "";
      this.form.companyW = "";
      this.form.position = "";
      // if(this.form.authtype!=''){
      //   this.subdisabled = false;
      // }
      // if(this.form.authtype=='高校用户/学生'){
      //   this.$message.success('您好，请联系您的相关任课教师处理您的问题');
      //   this.subdisabled = true;
      // }
      // else {

      // }
      this.subdisabled = false;
    },
    backFun() {
      window.location.href = "https://www.educg.net/";
      return false;
    },


    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
    resetFormKK() {
      this.$refs.ruleFormKK.resetFields();
    },
  },
};
</script>

  <style lang="scss">
@import "../assets/css/bootstrap.min.css";
@import "../assets/fs5/css/all.css";

div,
p,
ul,
li,
input,
body {
  padding: 0;
  margin: 0;
  list-style: none;
  background-color: #fff !important;
}

.mi label::before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}
#sub-footer {
  border-top: 1px solid #ddd;
  background: #f7f7f7;
  text-shadow: none;
  padding: 0;
  padding-top: 30px;
  margin: 20px 0 0 0;
}
.ul-add-li li,
.link-list li {
  padding-bottom: 10px;
}
/* tile uploaded pictures */
// .upload-list-inline >>> .ant-upload-list-item {
//   float: left;
//   width: 200px;
//   margin-right: 8px;
// }
// .upload-list-inline >>> .ant-upload-animate-enter {
//   animation-name: uploadAnimateInlineIn;
// }
// .upload-list-inline >>> .ant-upload-animate-leave {
//   animation-name: uploadAnimateInlineOut;
// }
.zl_box {
  position: relative;
  //   height: 340px;
  //   width: 100%;
  //   background: #0675c0;

  background-size: cover;
  .mask {
    position: absolute;
    background: #000;
    opacity: 0.3;
    // height: 277px;
    bottom: 0;
    width: 100%;
    top: -60px;
    z-index: 0;
    // border-radius: 46px;
  }
}
.zl {
  background: #fff;
  position: relative;
}
.box-header {
  background: rgba(255, 255, 255);
  position: sticky;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  top: 0;
  box-shadow: 1px 1px 5px 2px #dadada;
  z-index: 100;
}
.rightlogo {
  // background: url(https://www.jq22.com/demo/jqueryxysmoban201909092346/dist/images/header-bg-right.svg) right top no-repeat;
  background: url(https://www.qmth.com.cn/img/ny_yyys_banner_bg.jpg) no-repeat;

  background-size: cover;
}
.cta-btn {
  color: rgba(255, 255, 255, 0.75);
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
  font-size: 14px;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.5px;
  display: inline-block;
  padding: 6px 16px;
  border-radius: 2px;
  transition: 0.5s;
  margin: 10px;
  border-radius: 50px;
  // border: 2px solid #f6b024;
  color: #fff;
  // background: #f6b024;
}
.con {
  position: relative;
  width: 1000px;
  margin: 30px auto;
  margin-top: -40px;
  // background: #fff;
  padding: 0 50px;
  z-index: 99;
  background-color: transparent !important;
  // background: url(https://www.jq22.com/demo/jquerybootstrapsGsqymoban202107021414/assets/images/service/line-three.png)  no-repeat;
  // height:70vh;
  background-position: right;
  background-size: 3%;
  .from-title {
    text-align: center;
    margin-bottom: 20px;
  }
  .ant-btn-primary {
    //   background-color: #5c54ff;
    // border-color: #5c54ff;
  }
  // box-shadow: 1px 1px 4px 1px #f1f1f1;
  // .el-input__inner{
  //     background:#ffffff;
  //     border: 1px solid #e6e2e2;
  //     color: #57595a;
  // }
  // .el-select .el-input .el-select__caret{
  //     color:#e6e2e2;
  // }
  // .el-select-dropdown,.el-select .el-input.is-focus .el-input__inner{
  //     border: 1px solid #e6e2e2;    background-color: #fff;
  // }
  // .el-select-dropdown__item{
  //     color: #4a4949;
  // }
  // .el-select-dropdown{
  //     border: 1px solid #e6e2e2;    background-color: #fff;
  // }
  .ant-form {
    width: 100%;
    background: #fff;
    padding: 50px 0;
    margin: 0 auto;
    box-shadow: 0px 5px 16.74px 1.26px rgba(60, 57, 90, 0.14);
    border-radius: 10px;
  }
  .mask {
    position: absolute;
    background: #000;
    opacity: 0.5;
    height: 277px;
    width: 100%;
    top: -60px;
    z-index: 0;
    border-radius: 46px;
  }
  .ant-form {
    text-align: left;
  }
  .ant-form-item {
    width: 100%;

    display: inline-block;
  }
  .sub .ant-col-14 {
    width: 100%;
  }

  .kh {
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 20px;
  }
  .el-divider {
    background-color: #ffffff;
  }
}
.conDD {
  padding: 150px 0 50px;
  text-align: center;
  // background: url(https://www.jq22.com/demo/jquerybootstrapJymoban202107231802/assets/images/bg-img/01.jpg) no-repeat;
  height: 300px;
  background: url(../assets/ysys.jpg) no-repeat;

  background-size: cover;

  color: #fff;

  h1 {
    font-size: 50px;
    position: relative;
    z-index: 99;
  }

  p {
    color: #333;

    font-size: 17px;
    font-weight: 500;

    span {
      margin: 0 10px;
    }
  }
}

@media screen and (max-width: 1240px) {
  .con {
    width: 90%;
  }

  .minH {
    width: 80px;
  }
  .minF {
    font-size: 12px !important;
    display: none;
  }
  .zl .ant-divider-inner-text {
    padding: 0 !important;
  }
  .divider {
    font-size: 14px !important;
    i {
      font-size: 14px !important;
    }
  }
}
@media screen and (max-width: 1000px) {
  .zl_box h1 {
    font-size: 30px;
  }
  .conDD {
    padding-top: 70px;
  }
}
@media screen and (max-width: 860px) {
  h3{
    font-size:18px}
  .con {
    padding: 0 !important;
    width: 97%;
  }
  .con .ant-form {
    padding-left: 10px;
    padding: 10px;
  }
  .zl_box h1 {
    font-size: 20px;
  }
  .zl_box .sc-window {
    font-size: 12px !important;
  }
}
@media screen and (max-height: 860px) {
  .con {
    // margin-top: -260px;
  }
}
.zl footer {
  text-align: left;
  padding: 50px 0 0 0;
  background: #f2f2f2;
  a {
    color: #777;
  }
}
.zl footer ul.link-list li a:hover {
  color: #333;
  text-decoration: none;
}
.zl a:hover {
  color: #333;
  text-decoration: none;
}
// .ant-form-item{
//     margin-bottom:12px;
// }
.el-message--success {
  padding: 40px !important;
  // background: #ccc;
}

</style>
  <style >
  .zl .ant-menu-horizontal{
  margin-top:0;
}
  </style>